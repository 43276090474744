<template>
  <div class="container">
    <nav-bar header-title="小班课训练报告" :show-back="true" :header-back="appBack" :showShare="isEmpty ? false : true" backColor="#ffffff"   @right-txt-click="goShare"/>
    <div class="img-box">
      <img class="bg" :src="record.courseImg">
      <img class="mask" src="https://img.chaolu.com.cn/ACT/mini-records/mask.png" alt="">
    </div>
    <div class="content-p">
      <div class="app-share" @click="goShare" v-if="appTypeStr === 'mini' && !isEmpty">
        <svg t="1646279138239" class="share-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2112" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200">
          <path fill="#fff" fill-rule="nonzero" d="M874.666667 544c-17.066667 0-32 14.933333-32 32v256c0 6.4-4.266667 10.666667-10.666667 10.666667H192c-6.4 0-10.666667-4.266667-10.666667-10.666667V192c0-6.4 4.266667-10.666667 10.666667-10.666667h256c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32H192C151.466667 117.333333 117.333333 151.466667 117.333333 192v640c0 40.533333 34.133333 74.666667 74.666667 74.666667h640c40.533333 0 74.666667-34.133333 74.666667-74.666667V576c0-17.066667-14.933333-32-32-32z" p-id="2113"></path>
          <path fill="#fff" fill-rule="nonzero" d="M874.666667 117.333333H640c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h157.866667L509.866667 467.2c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333l285.866667-285.866667V384c0 17.066667 14.933333 32 32 32s32-14.933333 32-32V149.333333c0-17.066667-14.933333-32-32-32z" p-id="2114"></path>
        </svg>
      </div>
      <div class="record">
        <div class="left">
          <p class="mt12 fw4"><span class="mr20">{{record.venueTitle}}</span><span>{{record.teacherName}}</span></p>
          <p class="f24 fw4 mt20">{{record.schemeTitle}}</p>
          <p class="f44 fw5">{{record.courseTitle}}</p>
        </div>
        <div class="tc mr20" v-if="!isEmpty">
          <img class="food" :src="record.foodImg" alt="">
          <p class="jzkg">≈消耗{{record.foodName}}</p>
        </div>
      </div>
      <div class="content-b">
        <div class="user-detail box">
          <div class="user-img">
            <img :src="record.userImg" alt="">
            <div>
              <p class="f26">{{record.userName}}</p>
              <p class="f22 mt14">{{ record.lessonDetailTime }}</p>
            </div>
          </div>
          <div class="flex mt20" v-if="!isEmpty">
            <div class="tc">
              <img class="fat" :src="record.medalImg" alt="">
              <p class="fat-text">班级热量排行：<span class="c-0">{{ record.lessonRank }}</span></p>
            </div>
            <div class="user-data">
              <div class="mt24">
                <p><span class="BebasNeueBold f52 white">{{ record.kcal }}</span> <span>Kcal</span> </p>
                <p class="mt4">消耗热量</p>
              </div>
              <div class="pl30 border-box mt24">
                <p class="BebasNeueBold f52 white">{{record.runtime | time}}</p>
                <p class="mt4">训练时长</p>
              </div>
              <div class="mt44">
                <p><span class="BebasNeueBold f52 white">{{record.jzkg}}</span> <span>g</span> </p>
                <p class="mt4">消耗脂肪</p>
              </div>
              <div class="pl30 border-box mt44">
                <p><span class="BebasNeueBold f52 white">{{ record.rateval }}</span> <span>%</span> </p>
                <p class="mt4">训练百分比</p>
              </div>
            </div>
          </div>
          <div v-if="isEmpty">
            <P class="f28">以下情况可能导致没有训练报告哦</P>
            <P class="f24 mt12">1.课程当天没有来上课或未佩戴心率带上课</P>
            <P class="f24 mt8">2.心率带账号绑定的微信手机号与超鹿手机号不是同一个</P>
            <P class="f24 mt8">3.运动心率数据推送存在延迟，请耐心等待哦</P>
          </div>
        </div>
        <div class="heartbeat" v-if="!isEmpty">
          <p class="title">
            <span></span>
            <span class="f32 white pub_onefull lh32">心率</span>
            <span class="lh11">课程时间：{{ record.lessonTime }}</span>
          </p>
          <div class="h-content box">
            <div class="h-c-total">
              <div>
                <p class="f26 white mb16">平均心率</p>
                <p class="f24"><span class="BebasNeueBold f56 mr10 c-3">{{ record.xinlu }}</span>次/分</p>
              </div>
              <div>
                <p class="f26 white mb16">最大心率</p>
                <p class="f24"><span class="BebasNeueBold f56 mr10 c-5">{{ record.maxxl }}</span>次/分</p>
              </div>
              <div>
                <p class="f26 white mb16">最小心率</p>
                <p class="f24"><span class="BebasNeueBold f56 mr10 white">{{ record.minxl }}</span>次/分</p>
              </div>
            </div>
            <div>
              <line-chart v-if="dtoList.length" :dtoList="dtoList" :pieces="pieces"></line-chart>
            </div>
            <bar :record="record" />
          </div>
        </div>
        <div class="strength" v-if="!isEmpty">
          <p class="title">
            <span></span>
            <span class="f32 white pub_onefull lh32">训练强度</span>
          </p>
          <div class="box">
            <p class="c-0 f32 fw6">{{ record.strengthtxt }}</p>
            <p class="mt20 f22">{{ record.strengthdesc }}</p>
          </div>
        </div>
        <div class="ranking" v-if="!isEmpty">
          <p class="title">
            <span></span>
            <span class="f32 white pub_onefull lh32">{{record.month}}月排行榜</span>
            <span class="lh11" @click="goNew">查看最新<van-icon name="arrow" /></span>
          </p>
          <p class="mt30">注：以下为报告生成时您的排名数据</p>
          <div class="box tc pub_avg">
            <div>
              <p class="f24 mb24">同类型课程累计消耗</p>
              <p><span class="BebasNeueBold f56 mr8 white">{{ record.schemeConsumedKcal }}</span>Kcal</p>
            </div>
            <div>
              <p class="f24 mb24">小班课累计上课消耗</p>
              <p><span class="BebasNeueBold f56 mr8 white">{{ record.microTotalConsumedKcal }}</span>Kcal</p>
            </div>
          </div>
          <div class="box-bg1">
            <p class="f24 row-start-center mb40">
              <img class="icon" src="https://img.chaolu.com.cn/ACT/mini-records/icon-city.png" alt="">
              <span class="pub_onefull c-6">城市排名</span>
              <span class="white">{{ record.cityTitle }}</span>
            </p>
            <p class="flex mb24">
              <span class="white f26">同类型课程</span>
              <span class="f22 pub_onefull">（累计最高{{record.citySchemeMax}}kcal）</span>
              <span class="f36 white">{{ record.citySchemeRank }}</span>
            </p>
            <p class="flex">
              <span class="white f26">小班课</span>
              <span class="f22 pub_onefull">（累计最高{{record.cityMicroMax}}kcal）</span>
              <span class="f36 white">{{ record.cityMicroRank }}</span>
            </p>
          </div>
          <div class="box-bg1 box-bg2">
            <p class="f24 row-start-center mb40">
              <img class="icon" src="https://img.chaolu.com.cn/ACT/mini-records/icon-venue.png" alt="">
              <span class="pub_onefull c-6">门店排名</span>
              <span class="white">{{ record.venueTitle }}</span>
            </p>
            <p class="flex mb24">
              <span class="white f26">同类型课程</span>
              <span class="f22 pub_onefull">（累计最高{{record.venueSchemeMax}}kcal）</span>
              <span class="f36 white">{{ record.venueSchemeRank }}</span>
            </p>
            <p class="flex">
              <span class="white f26">小班课</span>
              <span class="f22 pub_onefull">（累计最高{{record.venueMicroMax}}kcal）</span>
              <span class="f36 white">{{record.venueMicroRank}}</span>
            </p>
          </div>
        </div>
        <div class="tc mt78" v-if="isEmpty">
          <img class="empty" src="https://img.chaolu.com.cn/ACT/mini-records/empty.png" alt="">
          <p class="f26 mt16">暂无训练记录</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import wx from 'weixin-js-sdk'
import { newAppBack, initBack, appOpenWeb } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import lineChart from './components/Line'
import bar from './components/Bar'
import { getParam } from '@/lib/utils'

export default {
  mixins: [userMixin],
  components: {
    navBar, lineChart, bar
  },
  data() {
    return {
      userInfo: { // 用户信息
        qrCode: '',
        nickName: '',
        headImg: '',
      },
      record: {

      },
      isEmpty: false,
      appointId: '',
      dtoList:[],
      pieces: [],
    }
  },
  computed: {},
  async created() {
    document.body.style.backgroundColor='#18191C'
    await this.$getAllInfo()
    initBack()
    this.appointId = getParam().appointId
    this.getData()
  },
  filters:{
    time(t){
      let min = Math.floor(t/60),s = t%60
      return (min<9?'0'+min:min) +'’'+(s<9?'0'+s:s)+'’’'
    },

  },
  methods: {
    appBack: newAppBack,
    getData() {
      this.$toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      this.$axios.post(`${this.baseURLApp}/micro/TrainingRecord/userDetailData`, {
        appointId: this.appointId,
      }).then(res => {
        this.record = res.data
        this.isEmpty = res.data.whetherData ==1?false:true
        this.dtoList = res.data.dtoList
        this.pieces = res.data.pieces
        this.$toast.clear()
        wx.miniProgram.postMessage({
          data: {
            type: 'share',
            shareImage: this.record.courseImg,
            shareTitle: this.record.schemeTitle,
            shareUrl: `/pages/miniCampPages/miniCampDetailCourse/index?schemeId=${this.record.schemeId}`,
          },
        })
      })
    },
    goShare() {
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/mini-records/share&userId=1&token=1&appointId=${this.appointId}`
        })
      } else {
        this.$router.push(`/mini-records/share?appointId=${this.appointId}`)
      }
      // appOpenWeb('',  `${window.location.origin}/#/mini-records/share?appointId=${this.appointId}`)
    },
    goNew(){
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/medal/rank&userId=1&token=1&businessType=MICRO&from=ab`
        })
      } else {
        this.$router.push(`/medal/rank?businessType=MICRO&from=ab`)
      }

      // appOpenWeb('',  `${window.location.origin}/#/medal/rank?businessType=MICRO`)
    },

  },
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  min-height: 100vh;
  color: #fff;
  overflow: hidden;
  background: #18191C;

  .img-box {
    width: 100%;
    position: relative;

    .bg {
      width: 100%;
    }

    .mask {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  .content-p {
    padding-top: calc(130px + constant(safe-area-inset-top));
    padding-top: calc(130px + env(safe-area-inset-top));
    position: absolute;
    top: 0px;
    width: 100%;
    box-sizing: border-box;
    .app-share {
      position: absolute;
      right: 36px;
      top: 32px;
    }
    .share-icon{
      width: 50px;
      height: 50px;
      display: flex;
      margin-right: 13px;
    }
    .c-0 {
      color: #FF6E00;
    }

    .c-1 {
      color: #7483FF;
    }

    .c-2 {
      color: #4BD9EE;
    }

    .c-3 {
      color: #52D7A6;
    }

    .c-4 {
      color: #F7B65D;
    }

    .c-5 {
      color: #FE6675;
    }

    .c-6 {
      color: #DDDDDD;
    }

    .record {
      display: flex;
      font-size: 22px;
      box-sizing: border-box;
      padding: 0px 32px;
      .left {
        display: flex;
        flex-direction: column-reverse;
        flex: 1;
        padding-bottom: 20px;
      }

      .food {
        display: inline-block;
        width: 176px;
      }

      .jzkg {
        font-weight: 400;
        padding: 10px 20px;
        border-radius: 300px;
        background: rgba(255, 255, 255, 0.15);
      }

    }
    .content-b{
      background: #18191C;
      padding: 0px 32px 78px;
    }

    .box {
      margin-top: 28px;
      border: 1px solid rgba(255, 255, 255, 0.10);
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.02);
      padding: 44px 40px;
      box-sizing: border-box;
    }

    .user-detail {

      .user-img {
        display: flex;
        margin-bottom: 40px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 80px;
          margin-right: 24px;
        }

      }

      .fat {
        width: 154px;
      }

      .fat-text {
        font-size: 24px;
        padding: 12px 24px;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 50px;
        font-weight: 400;
        margin: 28px 0 0;
      }

      .user-data {
        flex: 1;
        margin-left: 56px;
        display: flex;
        flex-wrap: wrap;
        color: rgba(255, 255, 255, 0.60);
        font-size: 24px;

        > div {
          width: 50%;
        }
        .mt24 {
          margin-top: 24px;
        }
        .mt44 {
          margin-top: 44px;
        }
      }
    }

    .heartbeat,
    .strength,
    .ranking {
      margin-top: 70px;
      color: #9AA1A9;
      font-size: 22px;

      // background: #18191C;
      .title {
        display: flex;
        align-items: center;

        span:first-child {
          display: inline-block;
          width: 8px;
          height: 28px;
          background-color: #FF5A00;
          margin-right: 12px;
        }
      }
    }


    .heartbeat {
      .h-content {
        .h-c-total {
          display: flex;
          justify-content: space-between;
        }


      }

    }

    .ranking {
      background: #18191C;
      .box-bg1 {
        background-image: url('https://img.chaolu.com.cn/ACT/mini-records/city.png');
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 28px 32px;
        margin-top: 28px;
        box-sizing: border-box;
      }

      .box-bg2 {
        background-image: url('https://img.chaolu.com.cn/ACT/mini-records/venue.png');
      }

      .icon {
        width: 28px;
        margin-right: 8px;
      }
    }

    .empty {
      width: 320px;
    }
  }
}
</style>

<template>
  <div class="bottom">
    <div class="user row-between-center">
      <div class="">
        <p class="f28 fw6">{{ record.schemeTitle }}</p>
        <p class="p2 f24">扫码加入班级一起练！</p>
      </div>
      <img class="qr" :src="`data:image/png;base64,${record.qrCode}`" alt="">
    </div>
    <img src="https://img.chaolu.com.cn/ACT/mini-records/logo.png" alt="" class="logo">
  </div>
</template>
<script>

export default {
  props: ['record']
}
</script>

<style lang='less' scoped>
.bottom {
  margin: 36px 0 0;

  .user {
    margin: 0 0 40px;
    color: #fff;

    .qr {
      width: 112px;
    }

    .p2 {
      color: #C3C3C3;
      margin: 12px 0 0;
    }
  }

  .logo {
    width: 176px;
  }
}
</style>

<template>
  <div class="h-c-detail">
    <p>
      <span class="">Z0：休闲状态</span>
      <!-- <span>&lt;88</span> -->
      <span class="bar"><span class="b-0" :style="progress(record.ext0)"></span></span>
      <span class="white w13p">{{ record.ext0 | time }}</span>
    </p>
    <p>
      <span class="c-1">Z1：热身状态</span>
      <!-- <span>88-99</span> -->
      <span class="bar"><span class="b-1" :style="progress(record.ext1)"></span></span>
      <span class="white w13p">{{ record.ext1 | time }}</span>
    </p>
    <p>
      <span class="c-2">Z2：脂肪燃烧</span>
      <!-- <span>99-120</span> -->
      <span class="bar"><span class="b-2" :style="progress(record.ext2)"></span></span>
      <span class="white w13p">{{ record.ext2 | time }}</span>
    </p>
    <p>
      <span class="c-3">Z3：心肺提升</span>
      <!-- <span>120-150</span> -->
      <span class="bar"><span class="b-3" :style="progress(record.ext3)"></span></span>
      <span class="white w13p">{{ record.ext3 | time }}</span>
    </p>
    <p>
      <span class="c-4">Z4：乳酸阈值</span>
      <!-- <span>150-175</span> -->
      <span class="bar"><span class="b-4" :style="progress(record.ext4)"></span></span>
      <span class="white w13p">{{ record.ext4 | time }}</span>
    </p>
    <p>
      <span class="c-5">Z5：极限警示</span>
      <!-- <span>&gt;175</span> -->
      <span class="bar"><span class="b-5" :style="progress(record.ext5)"></span></span>
      <span class="white w13p">{{ record.ext5 | time }}</span>
    </p>
  </div>
</template>

<style lang="less">
.h-c-detail {
  margin-top: 54px;
  color: #9AA1A9;
  p {
    display: flex;
    align-items: center;
    font-size: 22px;
    span:not(span:last-child) {
      margin-right: 24px;
    }

    // span:nth-child(2) {
    //   width: 96px;
    //   color: #9AA1A9;
    // }

    .bar {
      flex: 1;
      overflow: hidden;
      border-radius: 16px;
      height: 16px;
      position: relative;

      > span {
        display: inline-block;
        height: 16px;
        border-radius: 16px;
        position: absolute;
        top: 0px;
      }
    }
  }

  p+p {
    margin-top: 28px;
  }

  .b-0 {
      background: #9AA1A9;
    }

    .b-1 {
      background: #7483FF;
    }

    .b-2 {
      background: #4BD9EE;
    }

    .b-3 {
      background: #52D7A6;
    }

    .b-4 {
      background: #F7B65D;
    }

    .b-5 {
      background: #FE6675;
    }
    .c-1 {
      color: #7483FF;
    }

    .c-2 {
      color: #4BD9EE;
    }

    .c-3 {
      color: #52D7A6;
    }

    .c-4 {
      color: #F7B65D;
    }

    .c-5 {
      color: #FE6675;
    }
}
</style>

<script>

export default {
  props: {
    record: {
      type: Object
    },
  },
  data() {
    return {

    }
  },
  filters: {
    time(t) {
      let min = Math.floor(t / 60), s = t % 60
      return (min < 10 ? '0' + min : min) + '’' + (s < 10 ? '0' + s : s) + '’’'
    },

  },
  mounted() {

  },
  methods: {
    progress(m) {
      return { width: (m / this.record.runtime * 100).toFixed(2) + '%' }
    }
  },
}
</script>

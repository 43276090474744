<template>
  <div class="container">
    <nav-bar header-title="小班课训练报告" :show-back="true" :header-back="appBack" />

    <!-- <div class="team-list flex">
      <div @click="goTeamDetail(item.id)" class="team-item flex-none row-center-center" v-for="item in teamList" :key="item.id">
        <img :src="item.teamLogo" alt="" class="avator">
        <div>
          <p class="f28 fw6">{{ item.teamName }}</p>
          <p class="p2 f22">你共消耗<span class="f28 fw6">{{ item.calorieUserHistory }}</span>kcal</p>
        </div>
      </div>
    </div>
    <div @click="jump('/mini-team/my-team')" class="my-team row-between-center">
      <div>我的战队</div>
      <van-icon name="arrow" />
    </div> -->

    <van-list v-model="loading" :finished="finished" :immediate-check="false" @load="getRecord">
      <div v-for="monthData, index in recordList" :key="index">
        <div class="title-box f28 row-start-center">{{ monthData.date }}</div>
        <div class="record-item" @click="goDetail(item)" v-for="item in monthData.microTrainingRecordBaseDTOList"
          :key="item.lessonId">
          <div class="row-between-center">
            <p class="t1 f32 fw6 ellipsis">{{ item.courseTitle }}</p>
            <van-icon name="arrow" />
          </div>
          <p class="f24">{{ item.lessonStartTime }}</p>
          <div v-if="item.whetherData" class="data row-between-center">
            <div class="row-center-center">
              <img src="https://img.chaolu.com.cn/ACT/mini-records/hot.png" alt="" class="icon">
              <span class="f24">{{ item.kcal }}Kcal</span>
            </div>
            <div class="row-center-center">
              <img src="https://img.chaolu.com.cn/ACT/mini-records/kg.png" alt="" class="icon">
              <span class="f24">{{ item.jzkg }}g</span>
            </div>
            <div class="row-center-center">
              <img src="https://img.chaolu.com.cn/ACT/mini-records/love.png" alt="" class="icon">
              <span class="f24">{{ item.rateval }}%</span>
            </div>
            <div class="row-center-center">
              <img src="https://img.chaolu.com.cn/ACT/mini-records/rank.png" alt="" class="icon">
              <span class="f24">班级排名{{ item.lessonRank }}</span>
            </div>
          </div>
          <data v-else class="data-no row-between-center">
            <div class="row-center-center">
              <img src="https://img.chaolu.com.cn/ACT/mini-records/no.png" alt="" class="icon">
              <span class="f24">暂无训记报告</span>
            </div>
          </data>
        </div>
      </div>
    </van-list>

    <div v-if="!recordList.length" class="empty row-center-center">
      <img src="https://img.chaolu.com.cn/ACT/double11-2022/warm-up/empty.png" alt="">
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk"
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, appOpenWeb } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {

      userInfo: { // 用户信息
        qrCode: '',
        nickName: '',
        headImg: '',
      },

      loading: false,
      finished: false,
      params: {
        current: 1,
        size: 10,
        userId: ''
      },
      recordList: [],
      teamList: [], // 战队列表
    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo()
    initBack()

    this.params.userId = this.userId
    this.getRecord()
    this.getTeamList()
  },
  methods: {
    appBack: newAppBack,
    // 获取战队列表
    getTeamList() {
      this.$axios.post(`${this.baseURLApp}/microTeacherTeamUser/teamListUser`, {userId: this.userId}).then(res => {
        this.teamList = res.data ?? []
        this.teamList = this.teamList.slice(0, 10)
      })
    },
    jump(path, query = {}) {
      this.$router.push({ path, query })
    },
    getRecord() {

      this.loading = true
      this.$axios.post(`${this.baseURLApp}/micro/TrainingRecord/historyRecord`, this.params).then(res => {
        const list = res.data
        if (!list.length) {
          this.finished = true
          return
        }
        let len = list.reduce((pre, cur) => pre + cur.microTrainingRecordBaseDTOList.length, 0)
        console.log('hel', len);
        this.combineList(list)
        this.recordList = this.recordList.concat(list)
        if (len === 10) {
          this.params.current++
        } else {
          this.finished = true
        }
        this.loading = false
      })
    },
    combineList(list) {
      let r = this.recordList
      if (!r.length || !list.length) return
      if (list[0].date === r[r.length - 1].date) {
        const arr = list.shift()
        r[r.length - 1].microTrainingRecordBaseDTOList = r[r.length - 1].microTrainingRecordBaseDTOList.concat(arr.microTrainingRecordBaseDTOList)
      }
    },
    goDetail(item) {
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/mini-records/index&userId=1&token=1&appointId=${item.microAppointId}`
        })
      } else {
        this.$router.push(`/mini-records/index?appointId=${item.microAppointId}`)
      }

      // appOpenWeb('',  `${window.location.origin}/#/mini-records/index?appointId=${item.microAppointId}`)
    },
    goTeamDetail(id) {
      if (!id) return
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/mini-team/team-detail&teamId=${id}`
        })
      } else {
        this.$router.push(`/mini-team/team-detail?teamId=${id}`)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  background-color: #F5F5F5;
  padding: calc(132px + constant(safe-area-inset-top)) 32px calc(84px + constant(safe-area-inset-bottom));
  padding: calc(132px + env(safe-area-inset-top)) 32px calc(84px + env(safe-area-inset-bottom));

  .team-list {
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    .team-item {
      padding: 24px 20px;
      margin: 0 20px 0 0;
      background: #FFFFFF;
      border-radius: 12px;

      .avator {
        width: 100px;
        height: 100px;
        border-radius: 4px;
        object-fit: cover;
        margin: 0 20px 0 0;
      }

      .p2 {
        margin: 12px 0 0;
      }
    }
  }

  .my-team {
    width: 686px;
    background: #FFFFFF;
    height: 90px;
    margin: 24px auto 12px;
    border-radius: 12px;
    padding: 0 30px;
    box-sizing: border-box;
    div {
      background: url("https://img.chaolu.com.cn/ACT/mini-records/my_team%402x.png") no-repeat left center;
      background-size: 34px 34px;
      padding-left: 54px;
      color: #242831;
      font-size: 28px;
    }
  }

  .title-box {
    width: 100%;
    height: 84px;
    background: #F5F5F5;
  }

  .record-item {
    color: #3C454E;
    background-color: #fff;
    padding: 32px;
    margin: 0 0 24px;
    border-radius: 8px;

    .t1 {
      color: #242831;
      margin: 0 0 18px;
    }

    .data {
      height: 70px;
      padding: 0 16px;
      background-color: #F9FAFB;
      margin: 20px 0 0;

    }

    .data-no {
      margin: 20px 0 0;
    }
  }

  .icon {
    width: 30px;
    margin: 0 4px 0 0;
  }

  .empty {
    height: calc(100vh - 400px);

    img {
      width: 320px;
    }
  }
}</style>

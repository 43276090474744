<template>
  <div class="line-chart">
    <v-chart :options="polar" ref="vc" />

    <div id="abc">
      <img :src="chartImg" class="chartImg" alt="" />
    </div>
    <img :src="lastImg" class="chartImg" alt="">
  </div>
</template>

<style lang="less">
/**
 * 默认尺寸为 600px×400px，如果想让图表响应尺寸变化，可以像下面这样
 * 把尺寸设为百分比值（同时请记得为容器设置尺寸）。
 */
.line-chart {
  width: 100%;
  height: 540px;
}

.echarts {
  width: 104%;
  height: 100%;

}

.chartImg {
  width: 100%;
  display: block;
  margin: 200px auto;
}
</style>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/visualMap'   //引入visualMap相对应的js文件


export default {
  components: {
    'v-chart': ECharts
  },
  props: {
    dtoList: {
      type: Array,
      default: () => [[0, 0]],
    },
    pieces: {
      type: Array
    }
  },
  data() {
    let lineList = []
    for(let i = 0; i < this.dtoList.length; i++){
      lineList.push([this.dtoList[i].date, this.dtoList[i].x])
    }
    const dateList = lineList.map(function (item) {
      return item[0];
    });
    const valueList = lineList.map(function (item) {
      return item[1];
    });
    return {
      polar: {
        animation: false,
        visualMap: {
          type: 'piecewise',
          show: false,
          pieces: this.pieces,
          // pieces: [
          //   {
          //     gt: 0,
          //     lte: 88,
          //     color: '#9AA1A9'
          //   },
          //   {
          //     gt: 88,
          //     lte: 99,
          //     color: '#7483FF'
          //   },
          //   {
          //     gt: 99,
          //     lte: 120,
          //     color: '#4BD9EE'
          //   },
          //   {
          //     gt: 120,
          //     lte: 150,
          //     color: '#52D7A6'
          //   },
          //   {
          //     gt: 150,
          //     lte: 175,
          //     color: '#F7B65D'
          //   },
          //   {
          //     gt: 175,
          //     color: '#FE6675'
          //   }
          // ],
          outOfRange: {
            color: 'blue'
          }
        },
        title: {
          left: 'center',
          text: 'Gradient along the y axis'
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          data: dateList,
          axisLine: {
            lineStyle: {
              color: '#9AA1A9'
            },
            show: false,
          },
          axisTick: {
            show: false,
          },
          // boundaryGap: false,
          axisLabel: {
            align: 'left',
            formatter: function(value) {
              let a = value.split(':')
              return a[0] + ':' + a[1]
            },
          },
          offset: 4,
        },
        yAxis: {
          // axisLabel: {
          //   formatter: (value, index) => {
          //     console.log(value);
          //     // if (value > this.pieces[0].gt && value <= this.pieces[0].lte) return 'z0'
          //     // if (value > this.pieces[1].gt && value <= this.pieces[1].lte) return 'z1'
          //     // if (value > this.pieces[2].gt && value <= this.pieces[2].lte) return 'z2'
          //     // if (value > this.pieces[3].gt && value <= this.pieces[3].lte) return 'z3'
          //     // if (value > this.pieces[4].gt && value <= this.pieces[4].lte) return 'z4'
          //     // if (value > this.pieces[5].gt && value <= this.pieces[5].lte) return 'z5'
          //     return `z${index}`
          //   },

          // },
          axisLine: {
            lineStyle: {
              color: '#9AA1A9'
            },
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(255, 255, 255, .15)'
            }
          },

          axisTick: {
            show: false
          },
          offset: 4,
        },
        grid: {
          bottom: '10%',
          height: 210
        },
        series: {
          type: 'line',
          showSymbol: false,
          data: valueList
        }
      },
      chartImg: '',
      lastImg: '',

    }
  },

}
</script>
